'use strict'

angular
  .module 'lpaDispatching'
  .config ($stateProvider) ->
    $stateProvider
      .state 'dispatching',
        url: '/dispatch'
        templateUrl: 'lpa/lpa-dispatching/views/lpa-dispatching-map.tpl.html'
        controller: 'LpaDispatchingCtrl'
        controllerAs: 'dispatch'
        onEnter: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer true
            MundoSocket.toggleUnitEventConsumer true
            MundoSocket.toggleTenantEventConsumer true
        ]
        onExit: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer false
            MundoSocket.toggleUnitEventConsumer false
            MundoSocket.toggleTenantEventConsumer false
        ]
        resolve:
          filters: (DispatchFilters) ->
            DispatchFilters.getFilters()
          permissions: (UserPermissions) ->
            UserPermissions.get()
          DispUnitStatuses: (DispatchUnitStatus, RestUtils) ->
            RestUtils.getFullList DispatchUnitStatus
        data:
          navigation: false
